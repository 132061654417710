.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65;
  height: 100vh;
  background-color: white;
}

.chat__header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
}

.chat__header > h4 {
  font-weight: 500;
  color: gray;
}

.chat__name {
  color: black;
}

.chat__input {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid lightgray;
  background-color: #f5f5f5;
}

.chat__input > form {
  flex: 1;
}

.chat__messages {
  flex: 1;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat__messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat__messages {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chat__input > form > input {
  width: 98%;
  outline-width: 0;
  border: 1px solid lightgray;
  border-radius: 999px;
  padding: 5px;
}

.chat__input > form > button {
  display: none;
}
